import React, {Component} from "react";
import DataTable from "./DataTable/dataTable";

export default class Dashboard extends Component {
    render() {
        return (
            <div>
                <DataTable/>
            </div>
        )
    }
}

